import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class widgetTag extends Component{
	render(){
		return(
			<>
				<div className="widget widget_tag_cloud bg-black">
					<h4 className="widget-title">Tags</h4>
					<div className="tagcloud"> 
						<Link to="#">Îmbunătăţire</Link> 
						<Link to="#">Sănătate</Link> 
						<Link to="#">Implant</Link> 

						<Link to="#">General</Link>
					</div>
				</div>
				
			</>
		);
	}
}

export default widgetTag;