import React, { useEffect, useState  } from 'react';
import { DoctorService, accountService } from '../../_services';
import { doctorActions } from '../../_store';
// Images
import lineBg from '../../images/appointment/line-bg.png';
import appMobile from '../../images/appointment/mobile.png';
import appWomen from '../../images/appointment/women.png';
import appMapPin from '../../images/appointment/map-pin.png';
import appSetting from '../../images/appointment/setting.png';
import appCheck from '../../images/appointment/check.png';
import appChat from '../../images/appointment/chat.png';
import ptImg1 from '../../images/shap/trangle-orange.png';
import ptImg2 from '../../images/shap/wave-orange.png';
import ptImg3 from '../../images/shap/wave-blue.png';
import ptImg4 from '../../images/shap/circle-orange.png';

//class aboutSection extends Component{
	const AppointmentSection = () => {

			const [doctor, setDoctor] = useState(null);
			const [data, setData] = useState('');
			useEffect(() => {
	
				DoctorService.getAll().then(x => setDoctor(x.data));
	
				}, []);
		return(
			<>
				
				<section className="section-area account-wraper1">
					<div className="container-fluid">
						<div className="appointment-inner section-sp2" style={{backgroundImage: "url("+lineBg+")", backgroundRepeat:" no-repeat", backgroundPosition: "20px 140px"}}>
							<div className="container">
								<div className="row align-items-center">
									<div className="col-xl-5 col-lg-6 col-md-6">
										<div className="appointment-form form-wraper">
											<h3 className="title text-primary">Programează-te acum!</h3>
											<form action="#">
												<div className="form-group">
													<select className="form-select form-control text-dark" defaultValue={'DEFAULT'}>
													   
													 <option value="DEFAULT" disabled>Selectati serviciul dorit</option>
														
													<option value="1">Impant</option>
													<option value="2">Extractie</option>
													<option value="3">C</option>
													</select>
												</div>
												<div className="form-group">
													{/*<select className="form-select form-control text-dark" defaultValue={'DEFAULT'}>
													 <option value="DEFAULT" disabled>Selectati Doctorul dorit</option>

														<option value="1">Dr. Anda Dragomir</option>
														<option value="2">Dr. Andrei Capră</option>
														<option value="3">Dr. Monica Ionescu</option>
		</select>*/}
		<select name="doctorid" className="form-select form-control text-dark" defaultValue={'DEFAULT'}>
													 <option value="DEFAULT" disabled>Selectați Doctorul</option>
														{doctor && (
                  <>
                    {doctor.map((pos) => {
                      return (
                        <option value={pos.id} key={pos.id}>Dr. {pos.lastName} {pos.firstName}</option>
                    
                      );
                    })}
                  </>
                )}


													</select>
												</div>
												<div className="form-group">
													<input type="text" className="form-control text-dark" placeholder="Numele dumneavoastră"/>
												</div>
																								                                                                <div className="form-group">
													<input name="email" type="email" required className="form-control text-dark" placeholder="Email"/>
												</div>
												<div className="form-group">
													<input type="number" className="form-control text-dark" placeholder="Numere de telefon"/>
												</div>
												<div className="form-group">
													<input type="date" className="form-control text-dark"/>
												</div>
												<button type="submit" className="btn btn-secondary btn-lg">Programare Acum</button>
											</form>
										</div>
									</div>
									<div className="col-xl-7 col-lg-6 col-md-6">
										<div className="appointment-thumb">
											<img src={appMobile} alt=""/>
											<div className="images-group">
												<img className="img1" src={appWomen} alt=""/>
												<img className="img2" src={appMapPin} alt=""/>
												<img className="img3" src={appSetting} alt=""/>
												<img className="img4" src={appCheck} alt=""/>
												<img className="img5" src={appChat} alt=""/>
											</div>
										</div>
									</div>
								</div>					
							</div>	
							<img className="pt-img1 animate1" src={ptImg1} alt=""/>
							<img className="pt-img2 animate-wave" src={ptImg2} alt=""/>
							<img className="pt-img3 animate-wave" src={ptImg3} alt=""/>
							<img className="pt-img4 animate2" src={ptImg4} alt=""/>
							
						</div>
						
					</div>
				</section>
			
			</>
		);

}

export default AppointmentSection;