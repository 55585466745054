import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// Layout
import Header from "../layout/header2";
import Footer from "../layout/footer";
import Meta from '../../components/Meta'; 
// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";


class Booking extends Component{
	
	render(){
		return (
			<>
				
				<Header />
				
				<div className="page-content bg-black">
				<Meta title="Programare" />
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1 className="text-dark">Rezervare</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Acasă</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Rezervare</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
				
					<section className="section-area section-sp2 appointment-wraper">
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-xl-5 col-lg-6 col-md-6">
									<div className="appointment-form form-wraper">
										<h3 className="title text-secondary">Programează-te acum!</h3>
										<form>
											<div className="form-group ">
												<select className="form-select form-control text-dark">
													<option selected>Selectati ce doriti</option>
													<option value="1">Impant</option>
													<option value="2">Extractie</option>
													<option value="3">Curatare</option>
												</select>
											</div>
											<div className="form-group">
												<select className="form-select form-control text-dark">
													<option selected>Select Doctor</option>
													   <option value="1">Dr. Anda Dragomir</option>
													   <option value="2">Dr. Andrei Capră</option>
													   <option value="3">Dr. Monica Ionescu</option>
												</select>
											</div>
                                             <div className="form-group">
													<input type="text" className="form-control text-dark" placeholder="Numele dumneavoastră"/>
												</div>
																								                                                                <div className="form-group">
													<input name="email" type="email" required className="form-control" placeholder="Email"/>
												</div>
												<div className="form-group">
													<input type="number" className="form-control text-dark" placeholder="Numere de telefon"/>
												</div>
												<div className="form-group">
													<input type="date" className="form-control text-dark"/>
												</div>
												<button type="submit" className="btn btn-secondary btn-lg">Programare Acum</button>
										</form>
									</div>
								</div>
							</div>					
						</div>
					</section>
					
				</div>
				
				<Footer />
				
			</>
		);
	}
}

export default Booking;