import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

// Import Images
import lingBg2 from "../../images/background/line-bg2.png"
import animate1 from "../../images/shap/trangle-orange.png"
import animate2 from "../../images/shap/square-dots-orange.png"
import animateRotate from "../../images/shap/line-circle-blue.png"
import animateWave from "../../images/shap/wave-blue.png"
import testPic1 from "../../images/testimonials/pic1.webp"
import testPic2 from "../../images/testimonials/pic2.webp"
import testPic3 from "../../images/testimonials/pic3.webp"
import testPic4 from "../../images/testimonials/pic4.webp"
import testPic5 from "../../images/testimonials/pic5.webp"
import blogGridPic1 from "../../images/blog/grid/pic1.jpg"
import blogGridPic2 from "../../images/blog/grid/pic2.jpg"
import blogGridPic3 from "../../images/blog/grid/pic3.jpg"
import blogGridPic4 from "../../images/blog/grid/pic4.jpg"
import blogGridPic5 from "../../images/blog/grid/pic5.jpg"

// Team Content
const content = [
	{ 
		thumb: blogGridPic1,
		authorPic: testPic1,
		author: "Dr. Anda Dragomir",
		title: "În acest spital există chirurgi speciali",		
		date: "21 July 2022",
	},
	{ 
		thumb: blogGridPic2,
		authorPic: testPic2,
		author: "Dr. Andrei Capră",
		title: "Puteți obține o rețetă de diflucan online? ",		
		date: "20 July 2022",
	},
	{ 
		thumb: blogGridPic3,
		authorPic: testPic3,
		author: "Dr. Monica Ionescu",
		title: "De ce chirurgul de piele este considerat subestimat",		
		date: "19 July 2022",
	},
	{ 
		thumb: blogGridPic4,
		authorPic: testPic4,
		author: "Dr. Corina Besarab",
		title: "Îngrijirea dentară pentru femei este foarte importantă",		
		date: "18 July 2022",
	},
	{ 
		thumb: blogGridPic5,
		authorPic: testPic5,
		author: "Dr. Mircea Laslău",
		title: "Sănătatea va fi un lucru din trecut și iată de ce",		
		date: "17 July 2022",
	},
]

class LatestNewsSection extends Component{
	render(){
			
		const settings = {
			dots: false,
			infinite: true,
			speed: 1000,
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1191,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};
		
		return(
			<>
				
				<section className="section-area section-sp1 blog-area" style={{backgroundImage: "url("+lingBg2+")", backgroundPosition: "center", backgroundSize: "cover",}}>
					<div className="container">
						<div className="heading-bx text-center">
							<h6 className="title-ext text-light">Cele mai recente știri</h6>
							<h2 className="title">Cele mai recente știri ale noastre</h2>
						</div>
						<Slider {...settings} className="tt-slider blog-slide slider-sp0 slick-arrow-none">
							{content.map((item,index) =>(
								<div className="slider-item" key={index}>
									<div className="blog-card">
										<div className="post-media">
											<Link to="/blog-details"><img src={item.thumb} alt=""/></Link>
										</div>
										<div className="post-info">
											<ul className="post-meta">
												<li className="author"><Link to="/blog-details"><img src={item.authorPic} alt=""/>{item.author}</Link></li>

											</ul>
											<p className="date text-primary"><i className="far fa-calendar-alt"></i> {item.date}</p>
											<h5 className="post-title"><Link to="/blog-details" className="text-primary">{item.title}</Link></h5>		
											<Link to="/blog-details" className="btn btn-outline-primary btn-sm">Citeste mai mult <i className="btn-icon-bx fas fa-chevron-right"></i></Link>
										</div>
									</div>	
								</div>
							))}						
						</Slider>
					</div>
					<img className="pt-img1 animate1" src={animate1} alt=""/>
					<img className="pt-img2 animate2" src={animate2} alt=""/>
					<img className="pt-img3 animate-rotate" src={animateRotate} alt=""/>
					<img className="pt-img4 animate-wave" src={animateWave} alt=""/>
				</section>
				
			</>
		);
	}
}

export default LatestNewsSection;