import React, { Component } from 'react';
import date1 from '../../Json_date/date.json';

class LatestNewsSection extends Component{
	render(){
		
		return(
			<>
				
				<section className="section-sp1 service-wraper2">
					<div className="container">
						<div className="row">
						 {date1.work.map((record) => (
							<div className="col-xl-3 col-sm-6 mb-30">

								<div className="feature-container feature-bx3">
								<img src={record.path} className="rounded" alt=""/>
									<h5 className="ttr-title">{record.title}</h5>
									<p>{record.text}</p>
								</div>
							</div>
							))}
							
						</div>
					</div>
				</section>
				
			</>
		);
	}
}

export default LatestNewsSection;