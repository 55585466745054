import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// Layout
import Header from "../layout/header2";
import Footer from "../layout/footer";
import Meta from '../../components/Meta'; 
// Elements
import AuthorProfile from "../elements/author-profile";
import CommentList from "../elements/comment-list";
import CommentRespond from "../elements/comment-respond";
import WidgetTag from "../elements/widget-tag";
import WidgetSearch from "../elements/widget-search";
import WidgetGallery from "../elements/widget-gallery";
import WidgetRecentPosts from "../elements/widget-recent-posts";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import blogDefaultPic1 from "../../images/blog/default/pic1.jpg";
import testPic3 from "../../images/testimonials/pic3.webp";
import galleryPic2 from "../../images/gallery/pic2.jpg";
import galleryPic5 from "../../images/gallery/pic5.jpg";


class BlogDetails extends Component{
	
	render(){
		return (
			<>
				
				<Header />
				
				<div className="page-content bg-black">
				<Meta title="Blog" />
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1 className="text-dark">Detalii articol</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Acasă</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Detalii articol</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1 bg-black">
						<div className="container">
							<div className="row">
								<div className="col-md-12 col-lg-7 col-xl-8 mb-30 mb-md-50">
									<div className="blog-card blog-single">
										<div className="post-media">
											<img src={blogDefaultPic1} alt=""/>
										</div>
										<div className="info-bx bg-black">
											<ul className="post-meta">
												<li className="author"><Link to="/blog-details"><img src={testPic3} alt=""/> Dr. Monica Ionescu</Link></li>
												<li className="date"><i className="far fa-calendar-alt"></i> 19 July 2022</li>
											</ul>
											<div className="ttr-post-title">
												<h2 className="post-title">Sfaturi prețioase pentru a vă ajuta să vă îmbunătățiți.</h2>
											</div>
											<div className="ttr-post-text text-dark">
												<p>Trebuie doar să introduceți cuvântul cheie și să selectați tipul de cuvânt cheie pentru a genera o listă de 6 idei de titlu și sugestii. Dacă nu sunteți mulțumit de rezultate, puteți apăsa oricând butonul de reîmprospătare pentru a genera o nouă listă de titluri unice.</p>
												<blockquote className="wp-block-quote">
													<p>Odată ce ați obținut toate titlurile și l-ați ales pe cel mai bun, următorul lucru pe care trebuie să-l faceți este să creați un conținut magnetic. Specialiștii de marketing de conținut excelează la crearea de conținut.</p>
												</blockquote>
												<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
												<ul className="wp-block-gallery columns-6 is-cropped">
													<li className="blocks-gallery-item"><img alt="" src={galleryPic2}/></li>
													<li className="blocks-gallery-item"><img alt="" src={galleryPic5}/></li>
												</ul>
												<p>Trebuie doar să introduceți cuvântul cheie și să selectați tipul de cuvânt cheie pentru a genera o listă de 6 idei de titlu și sugestii. Dacă nu sunteți mulțumit de rezultate, puteți apăsa oricând butonul de reîmprospătare pentru a genera o nouă listă de titluri unice.</p>
												<p>A supraviețuit nu numai cinci secole, ci și saltului în compunerea electronică, rămânând în esență neschimbat. A fost popularizat în anii 1960 odată cu lansarea foilor Letraset care conțin pasaje Lorem Ipsum și, mai recent, cu software-ul de publicare desktop precum Aldus PageMaker, inclusiv versiuni de Lorem Ipsum.</p>
											</div>
											<div className="ttr-post-footer">
												<div className="post-tags">
													<strong>Tags:</strong>
													<Link to="#">Sănătate</Link> 
													<Link to="#">Creştere</Link> 
													<Link to="#">Viaţă</Link> 
												</div>
												<div className="share-post ml-auto">
													<ul className="social-media mb-0">
														<li><strong>Share:</strong></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.facebook.com/"><i className="fab fa-facebook-f"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.linkedin.com/"><i className="fab fa-linkedin-in"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://twitter.com/"><i className="fab fa-twitter"></i></a></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									
									<AuthorProfile />
									
									<div className="clear" id="comment-list">
										<div className="comments-area" id="comments">
											<h4 className="widget-title">8 Comments</h4>
											
											<div className="clearfix">
												
												<CommentList />
												
												<CommentRespond />
												
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-12 col-lg-5 col-xl-4 mb-30">
									<aside className="side-bar sticky-top aside-bx">
										
										<WidgetSearch />
										
										<WidgetRecentPosts />
										
										<WidgetGallery />
										
										<WidgetTag />
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
				<Footer />
				
			</>
		);
	}
}

export default BlogDetails;