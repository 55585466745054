import React, { useEffect, useState } from 'react';
//import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { accountService } from '../../_services';
//import { history } from '../../_helpers';
import { authActions } from '../../_store';
import { toast } from 'react-toastify';
// Import Images
import logo from "../../images/logo3.png";

//export { FormLogin };
const FormLogin = () => {
//function FormLogin() {
	const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
	console.log("authUser 1",authUser);
    const authError = useSelector(x => x.auth.error);
 const [data, setData] = useState('');
 const [linc, setLinc] = useState(0);
 const navigate = useNavigate();
 useEffect(() => {
	// redirect to home if already logged in
	if (authUser?.username !==undefined) 
	{
	navigate('/');
	}
//localStorage.removeItem("user");
	// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  /* useEffect(() => {
        // redirect to home if already logged in
		if (authUser?.username !==undefined) {
			console.log("authUser",authUser?.username);
			//localStorage.removeItem("user");
			//localStorage.removeItem("token");
			navigate('/');
			}
    }, [authUser]);*/

    // form validation rules 
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('e-mail este necesar'),
        password: Yup.string().required('Parola este necesara')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({ email, password }) {
    accountService.login(email, password)
    .then(() => {
              //  alertService.success('Înregistrare reușită, vă rugăm să vă verificați e-mailul pentru instrucțiuni de verificare', { keepAfterRouteChange: true });
				toast.success(email+'- Autentificare reusita', {
					position: "top-center",theme: "light"});
console.log('Autentificare reusita');
dispatch(authActions.login({ email, password }));
navigate('/dashboard');
//window.location.reload();

  return  
 // history.navigate('/');

 // history.navigate('/');
 //navigate('/');
 // history('/dashboard');

            })
            .catch(error => {

		setData(error);
		setLinc(linc+1);
		console.log(linc);

	});
	// return dispatch(authActions.login({ email, password }));
 }

    return (
		<>
			    {(linc>7) ? (            
            <div className="col-sm-12 offset-sm-8 mt-8 container flex">
    <div className="alert alert-info text-danger">
<h4>Ai incercati de prea multe ori. Reveniti mai tarziu sau contactati administratorul</h4>
            </div>
	    </div>

	    ) : ( 
				<div className="section-area account-wraper2">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-xl-5 col-lg-6 col-md-8">
								<div className="appointment-form form-wraper">
								
									<div className="logo">
									<Link to="/">
										<img src={logo} alt=""/>
										</Link>
									</div>
									<form onSubmit={handleSubmit(onSubmit)}>
										<div className="form-group">
											<input name="email" type="text" {...register('email')} className={`form-control text-dark ${errors.email ? 'is-invalid' : ''}`} placeholder="Email"/>
                            <div className="invalid-feedback">{errors.email?.message}</div>
										</div>
										<div className="form-group">
											<input name="password" type="password" {...register('password')} className={`form-control text-dark ${errors.password ? 'is-invalid' : ''}`} placeholder="Parola"/>
                            <div className="invalid-feedback">{errors.password?.message}</div>
                        </div>
										
										<div className="form-group">

											<button disabled={isSubmitting} className="btn mb-30 btn-lg btn-primary w-100">
                                             {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                Autentificare
                                            </button>
											{authError &&
                                            <div className="alert alert-danger mt-3 mb-0">{data}</div>
                                          }
											<Link to="/form-forget-password" data-toggle="tab">Aţi uitat parola</Link>
										</div>
										<div className="text-center mt-40">
											<p className="mt-0">Nu ai niciun cont?</p>
											<Link className="btn btn-lg btn-secondary w-100" data-toggle="tab" to="/form-register">Inregistreaza-te</Link>
										</div>											
									</form>
								</div>
							</div>
						</div>					
					</div>
				</div>
				
			
			)}
			</>
		)
	}


export default FormLogin;