import React, {useEffect} from 'react';
import Markup from './markup/markup';
import { Helmet, HelmetProvider } from 'react-helmet-async';
// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Fonts
import "./vendor/line-awesome/css/line-awesome.min.css";
import "./vendor/font-awesome/css/all.min.css";
import "./vendor/themify/themify-icons.css";

// StyleSheet
import './App.scss';


function App() {
	useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);
	return (
		<HelmetProvider>
		<div className="page-wraper">
			<Markup />
		</div>
		</HelmetProvider>
	);
}

export default App;