import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';
import Meta from '../../components/Meta'; 
// Layout
import Header from "../layout/header2";
import Footer from "../layout/footer";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import servicesPic1 from "../../images/services/pic1.jpg";
import pdf from "../../images/icon/pdf.png";
import doc from "../../images/icon/doc.png";

class ServiceDetail extends Component{
	
	render(){
		return (
			<>
				
				<Header />
				
				<div className="page-content bg-black">
				<Meta title="Cazuri" />
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1 className="text-dark">Detalii</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><a href="index.html"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Acasă</a></li>
											<li className="breadcrumb-item active" aria-current="page">Detalii serviciu</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 mb-30">
									<div className="ttr-media mb-30">
										<img src={servicesPic1} className="rounded" alt=""/>
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">De ce noi?</h2>
											<p className="mb-0">Deoarece dorim să-i oferim fiecărui client în parte o experiență stomatologică inedită, fără durere, eficientă și cu rezultate de lungă durată.</p>
										</div>
										<div className="row align-items-center">
											<div className="col-md-6 mb-30">
												<ul className="list-check-squer mb-0">
													<li>Dedicați pacienților</li>
													<li>Experiență, calitate & siguranță</li>
													<li>Cei mai buni medici stomatologi!</li>
													<li>Cele mai noi Tehnologii</li>
													<li>Fericire garantata</li>
												</ul>
											</div>
											<div className="col-md-6 mb-30">
												<div className="skillbar-box mb-30">
													<h6 className="title">Tehnologie avansata</h6>
													<div className="skillbar appear">
														<p className="skillbar-bar" style={{width: "96%"}}></p>
														<span className="skill-bar-percent">96%</span>
													</div>
												</div>
												<div className="skillbar-box mb-30">
													<h6 className="title">Medici stomatologi specialiști</h6>
													<div className="skillbar appear">
														<p className="skillbar-bar" style={{width: "79%"}}></p>
														<span className="skill-bar-percent">79%</span>
													</div>
												</div>
												<div className="skillbar-box mb-0">
													<h6 className="title">6 ani Experienta</h6>
													<div className="skillbar appear">
														<p className="skillbar-bar" style={{width: "75%"}}></p>
														<span className="skill-bar-percent">75%</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h4 className="title mb-10">La Clinica Dr. Andrei Capră</h4>
											<p className="mb-0">La Clinica Dr. Andrei Capră este o echipă de medici, tehnicieni și asistente medicale, care lucrează cu multă dăruire, iar împreună construim zâmbete, oferim pacienților rezultate sănătoase și sporirea încrederii în sine.</p>
											<p className="mb-0">Clinica Dr. Andrei Capră iți pune la dispoziție cei mai buni medici stomatologi competenți, calificați, cu o experiență solidă în practica medicală și servicii de înaltă calitate + tehnologie modernă.</p>
											<p className="mb-0">La Clinica Dr. Andrei Capră este o echipă de medici, tehnicieni și asistente medicale, care lucrează cu multă dăruire, iar împreună construim zâmbete, oferim pacienților rezultate sănătoase și sporirea încrederii în sine.</p>
										</div>
										<Accordion defaultActiveKey="0" className="accordion ttr-accordion1">
										<Accordion.Item eventKey="0">
											<Accordion.Header>Ce îmi oferă Clinica Dr. Andrei Capră ?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Performanța medicilor noștri, intervențiile lipsite de durere, rezultatele precise ale tratamentelor efectuate , fac ca pacienții sa pășească cu încredere în clinica noastră.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="1">
											<Accordion.Header>Cum poate asigura Clinica Dr. Andrei Capră eficiența și durabilitatea tratamentelor ?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Acestea pot fi asigurate prin intermediul materialelor de ultimă generație , a ultradotării clinicii , și a experienței profesionale a medicilor noștrii.</p>
																							                                                                </Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="2">
											<Accordion.Header>Pe lângă facilități moderne și experiența profesională a medicilor ce trebuie să vă mai ofere Clinica Dr. Andrei Capră?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">De la specialiști în stomatologie, până la asistente , receptive, management, echipa noastră face tot posibilul ca fiecare pacient să plece din clinica noastră cu zâmbetul pe buze.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="3">
											<Accordion.Header>Ce tipuri de sisteme suportați?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="4">
											<Accordion.Header>Te învățăm cum să te simți mai bine?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="5">
											<Accordion.Header>Cum va pot contacta?</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
											</Accordion.Body>
										</Accordion.Item>
										</Accordion>
									</div>
								</div>
								<div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">
											<ul className="service-menu">
												<li className="active"><Link to="/service-detail"><span>Curatarea dintilor</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-detail"><span>					Indreptare dinții</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-detail"><span>Tratamentul orificiilor (dinților)</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-detail"><span>Implanturi dentare</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-detail"><span>Examen dentar</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-detail"><span>Ingrijire dentara</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<div className="widget">
											<div className="brochure-bx">
												<h5 className="title-head">Download</h5>
												<Link to="#" className="download-link">
													<img src={pdf} alt=""/>
													<h5 className="title">Descărcați broșurile noastre</h5>
													<span>Download</span>
												</Link>
												<Link to="#" className="download-link">
													<img src={doc} alt=""/>
													<h5 className="title">Detaliile companiei noastre</h5>
													<span>Download</span>
												</Link>
											</div>
										</div>
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
				<Footer />
				
			</>
		);
	}
}

export default ServiceDetail;