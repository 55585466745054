import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { DoctorService, accountService } from '../../_services';
import { doctorActions } from '../../_store';
import http from "../../http-common";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
// Layout
import Header from "../layout/header2";
import Footer from "../layout/footer";
import Meta from '../../components/Meta'; 
// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import pic1 from "../../images/about/pic-1.jpg";
//import icon1 from "../../images/icon/icon1.png";
//import icon2 from "../../images/icon/icon2.png";
//import icon3 from "../../images/icon/icon3.png";
import animateWave from "../../images/shap/wave-blue.png";
import animate2 from "../../images/shap/circle-dots.png";
import animateRotate from "../../images/shap/plus-blue.png";
import { toast } from 'react-toastify';

//class ContactUs extends Component{
	const ContactUs = () => {
		const [doctor, setDoctor] = useState(null);
		const [data, setData] = useState('');
		useEffect(() => {

			DoctorService.getAll().then(x => setDoctor(x.data));

			}, []);
			const validationSchema = Yup.object().shape({
				email: Yup.string()
				.email('Adresa de e-mail este invalida')
				.required('Email este obligatoriu'),
				name: Yup.string().required('Numele este necesar')
			});
			const formOptions = { resolver: yupResolver(validationSchema) };
		
			// get functions to build form with useForm() hook
			const { register, handleSubmit, formState } = useForm(formOptions);
			const { errors, isSubmitting } = formState;
		
			function onSubmit({ name, email, phone, doctorid, message }) {
				console.log("Mes", email);
				http.post("/contact/", {
					name: name,
					mobile: phone,
					email: email,
					msg: message,
				  }).then(() => {

						toast.success('Mutumim, Mesajul a fost trimis', {
							position: "top-center",theme: "light"});

		  return  

		
					})
					.catch(error => {
						toast.error(error, {
							position: "top-center",theme: "light"});
				setData(error);

		
			});

		 }

		return (
			<>
				
				<Header />
				
				<div className="page-content">
				<Meta title="Contactaţi-ne" />
					<div className="banner-wraper">
						<div className="page-banner banner-lg contact-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1 className="text-dark">Contactaţi-ne</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Acasă</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Contactaţi-ne</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={animateWave} alt=""/>
							<img className="pt-img2 animate2" src={animate2} alt=""/>
							<img className="pt-img3 animate-rotate" src={animateRotate} alt=""/>
						</div>
					</div>
					
					<section className="">
						<div className="container">
							<div className="contact-wraper">
								<div className="row">
									<div className="col-lg-6 mb-30">
										<form className="form-wraper contact-form ajax-form" onSubmit={handleSubmit(onSubmit)} >
											<div className="ajax-message"></div>
											<div className="row">
												<div className="form-group col-md-12">
													<input name="name" type="text" required className="form-control text-dark" placeholder="Numele"/>
													<div className="invalid-feedback">{errors.name?.message}</div>
												</div>
												<div className="form-group col-md-12">
													<input name="email" type="email" required className="form-control text-dark" placeholder="Email"/>
													<div className="invalid-feedback">{errors.email?.message}</div>
												</div>
												<div className="form-group col-md-12">
													<input name="phone" type="text" required className="form-control text-dark" placeholder="Numere de telefon"/>
												</div>
												<div className="form-group col-md-12">

													<select name="doctorid" className="form-select form-control text-dark" defaultValue={'DEFAULT'}>
													 <option value="DEFAULT" disabled>Selectați Doctorul</option>
														{doctor && (
                  <>
                    {doctor.map((pos) => {
                      return (
                        <option value={pos.id} key={pos.id}>Dr. {pos.lastName} {pos.firstName}</option>
                    
                      );
                    })}
                  </>
                )}


													</select>
												</div>
												<div className="form-group col-md-12">
													<textarea name="message" required className="form-control text-dark" placeholder="Mesajul tau"></textarea>
												</div>
												<div className="col-lg-12">
	

												<button name="submit" type="submit" value="Submit" className="btn w-100 btn-secondary btn-lg">Trimite</button>

												</div>
											</div>
										</form>
									</div>
									<div className="col-lg-6 mb-30">
										<div className="contact-info ovpr-dark" style={{backgroundImage: "url("+pic1+")"}}>
											<div className="info-inner">
												<h6 className="title mb-30">În urma efectuării unei programări, vei fi contactat pentru confirmare
Programarea, se consideră efectuată, numai după confirmarea telefonică.
Vă mulțumim!</h6>
												<div className="icon-box">
													<h6 className="title"><i className="ti-map-alt"></i>Locație</h6>		
													<p>Str. Vlad Județul, Nr. 62, București, România</p>
												</div>
												<div className="icon-box">
													<h6 className="title"><i className="ti-id-badge"></i>Email &amp; Telefon</h6>		
													<Link to="#" className="text-white">capra.andrei@yahoo.com</Link>
													<p>+40 754 848 253</p>
												</div>
												<div className="icon-box">
													<h6 className="title"><i className="ti-world"></i>Urmează-ne</h6>
													<ul className="social-media">
														<li><a rel="noreferrer" target="_blank" href="https://twitter.com/"><i className="fab fa-twitter"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.linkedin.com/"><i className="fab fa-linkedin"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					
					<section className="section-area section-sp1">
						<div className="container">
							<iframe title="Locatie"  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2849.508209462832!2d26.128239014795113!3d44.42273647910242!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1feddf7410b2b%3A0x33656a6ce97a5af5!2sStrada%20Vlad%20Jude%C8%9Bul%2062%2C%20Bucure%C8%99ti!5e0!3m2!1sen!2sro!4v1667224976425!5m2!1sen!2sro" width="600" height="450" style={{ border: 0, width: "100%", height: "55vh" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
						</div>
					</section>
				
				</div>
				
				<Footer />
				
			</>
		);

}

export default ContactUs;