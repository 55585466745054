import React, { useEffect, useState } from 'react';
import {useNavigate, Link } from 'react-router-dom';
import Sticky from 'react-stickynode';

// Images
import logo from '../../images/logo3.png';
import logoWhite from '../../images/logo3.png';
import { useSelector, useDispatch } from 'react-redux';
import { accountService } from '../../_services';
import { authActions } from '../../_store';
import { toast } from 'react-toastify';

//class Header extends Component{
	const Header = () => {
		const navigate = useNavigate();
		var authUser = useSelector(x => x.auth.user);
		const dispatch = useDispatch();
		const [pers, setPers] = useState(localStorage.getItem('token'));
		var searchBtn = document.getElementById("quikSearchBtn");
		var searchForm = document.querySelector(".nav-search-bar");
		var closeBtn = document.getElementById("searchRemove");
		var menuIcon = document.querySelector(".menuicon");
		var menuLinks = document.querySelector(".menu-links");
		var menuClose = document.getElementById("menuClose");
		var navMenu = [].slice.call(document.querySelectorAll('.menu-links > ul > li'));

useEffect(() => {
	//authUser = useSelector(x => x.auth.user);
		// Search Form Popup
		setPers(localStorage.getItem('token'));
		 searchBtn = document.getElementById("quikSearchBtn");
         searchForm = document.querySelector(".nav-search-bar");
         closeBtn = document.getElementById("searchRemove");
		
		// Search Form Popup

		
        searchBtn.addEventListener('click',function(){
            searchForm.classList.add("show")
        })

        closeBtn.addEventListener('click',function(){
            searchForm.classList.remove("show")
        })
		
		// Mobile Menu Open
		 menuIcon = document.querySelector(".menuicon")
		 menuLinks = document.querySelector(".menu-links")
		 menuClose = document.getElementById("menuClose")

		menuIcon.addEventListener('click',function(){
			menuLinks.classList.add("show")
		})

		menuClose.addEventListener('click',function(){
			menuLinks.classList.remove("show")
		})
		navMenu = [].slice.call(document.querySelectorAll('.menu-links > ul > li'));
        for (var y = 0; y < navMenu.length; y++) {
            navMenu[y].addEventListener('click', function () { menuClick(this) });
        }
	}, [document.querySelectorAll('.menu-links > ul > li'), localStorage.getItem('token')]);	
		// Mobile Submenu open close function
       // var navMenu = [].slice.call(document.querySelectorAll('.menu-links > ul > li'));
        for (var y = 0; y < navMenu.length; y++) {
            navMenu[y].addEventListener('click', function () { menuClick(this) });
        }

        function menuClick(current) {
            const active = current.classList.contains("open")
            navMenu.forEach(el => el.classList.remove('open'));
            
            if(active){
                current.classList.remove('open') 
                console.log("active")
            } else{
                current.classList.add('open');
                console.log("close")
            }
        }
		
 
	
	const logout = () => {

		accountService.logout(authUser.id)
		 .then(() => {
					   toast.success("La revedere", {
						position: "bottom-center",theme: "light"});
		//setAnchorEl(null);
		dispatch(authActions.logout());
		navigate('/');
		//window.location.reload();
		
		 return   
				   })
				   .catch(error => {
	   toast.error(error);
				   });
			   };
	


		return(
			<>
				<header className="header rs-nav">
					<Sticky enabled={true} className="sticky-header navbar-expand-lg">
						<div className="menu-bar clearfix">
							<div className="container-fluid clearfix">
								<div className="menu-logo logo-dark">
									<Link to="/"><img src={logo} alt=""/></Link>
								</div>
								<button className="navbar-toggler bg-dark collapsed menuicon justify-content-end" type="button" data-bs-toggle="collapse" data-bs-target="#menuDropdown" aria-controls="menuDropdown" aria-expanded="false" aria-label="Toggle navigation">
									<span></span>
									<span></span>
									<span></span>
								</button>
								<div className="secondary-menu">
									<ul>
										<li className="search-btn"><button id="quikSearchBtn" type="button" className="btn-link"><i className="las la-search"></i></button></li>
										<li className="num-bx"><a href="tel:(+40)754848253"><i className="fas fa-phone-alt text-light"></i>+40 754 848 253</a></li>
										<li className="btn-area"><Link to="/contact-us" className="btn btn-primary shadow">CONTACTAŢI-NE<i className="btn-icon-bx fas fa-chevron-right"></i></Link></li>
									</ul>
								</div>
								<div className="menu-links navbar-collapse collapse justify-content-end" id="menuDropdown">
									<div className="menu-logo">
										<Link to="/"><img src={logoWhite} alt=""/></Link>
									</div>
									<ul className="nav navbar-nav">	

										<li>
											<Link to="/about-us">Despre noi <i className="fas fa-plus"></i></Link>
											<ul className="sub-menu">
												<li className="add-menu-left">
													<ul>
														<li><Link to="/about-us"><span>Andrei Capra</span></Link></li>
														<li><Link to="/team"><span>Echipa noastră</span></Link></li>
														<li><Link to="/faq"><span>FAQ's</span></Link></li>
														<li><Link to="/booking"><span>Programare</span></Link></li>
														{/*<li><Link to="/error-404"><span>Error 404</span></Link></li>  */}
														<li><Link to="/form-login"><span>Autentificare / Inregistreaza-te</span></Link></li>
													</ul>
												</li>
											</ul>
										</li>
										<li>
											<Link to="#">Servicii <i className="fas fa-plus"></i></Link>
											<ul className="sub-menu">
												<li className="add-menu-left">
													<ul>
														<li><Link to="/services"><span>Servicii</span> </Link></li>
														<li><Link to="/service-detail"><span>Cazuri</span></Link></li>
													</ul>
												</li>
											</ul>
										</li>
										<li><Link to="/error-404">Preturi</Link></li>
										<li><Link to="#">Blog <i className="fas fa-plus"></i></Link>
											<ul className="sub-menu left">
												<li><Link to="/blog-grid"><span>Articole</span></Link></li>
												<li><Link to="/blog-details"><span>Detalii blog</span></Link></li>
											</ul>
										</li>
										{pers ? (<>
											<li><Link to="#"><svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 1024 1024"><path fill="#fff" d="M288 320a224 224 0 1 0 448 0 224 224 0 1 0-448 0zm544 608H160a32 32 0 0 1-32-32v-96a160 160 0 0 1 160-160h448a160 160 0 0 1 160 160v96a32 32 0 0 1-32 32z"/></svg> <i className="fas fa-plus"></i></Link>
											<ul className="sub-menu left">
												<li><Link to="/dashboard"><span>Profil</span></Link></li>
												<li><Link to="/booking"><span>Programare</span></Link></li>
												<li><Link to="/blog-grid"><span>Istoric</span></Link></li>
												<li><Link to="#" onClick={logout}><span>Iesire</span></Link></li>
											</ul>
										</li>
										
										</>
										) : (
										<li><Link to="/form-login">Autentificare</Link></li>
										)}
									</ul>
									<ul className="social-media">
										<li><a rel="noreferrer" target="_blank" href="https://www.facebook.com/" className="btn btn-primary"><i className="fab fa-facebook-f"></i></a></li>
										<li><a rel="noreferrer" target="_blank" href="https://www.google.com/" className="btn btn-primary"><i className="fab fa-google"></i></a></li>
										<li><a rel="noreferrer" target="_blank" href="https://www.linkedin.com/" className="btn btn-primary"><i className="fab fa-linkedin-in"></i></a></li>
										<li><a rel="noreferrer" target="_blank" href="https://twitter.com/" className="btn btn-primary"><i className="fab fa-twitter"></i></a></li>
									</ul>
									<div className="menu-close" id="menuClose">
										<i className="ti-close"></i>
									</div>
								</div>
							</div>
						</div>
					</Sticky>
					<div className="nav-search-bar">
						<form>
							<input type="text" className="form-control" placeholder="Tastați pentru a căuta"/>
							<span><i className="ti-search"></i></span>
						</form>
						<span id="searchRemove"><i className="ti-close"></i></span>
					</div>
				</header>
				
			</>
		);

}

export default Header;
