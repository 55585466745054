import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from '../../_store';
// Layout
import Header from "../layout/header2";
import Footer from "../layout/footer";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";


	const Dasboard = () => {
		const [tabKey, initTabKey] = useState('one');
		const dispatch = useDispatch();
		const { user: authUser } = useSelector(x => x.auth);
		const { users } = useSelector(x => x.users);
		useEffect(() => {
			dispatch(userActions.getAll());
		
	
		}, []);
		return (
			<>
				
				<Header />
				
				<div className="page-content bg-black">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1 className="text-dark">Buna {authUser?.firstName} {authUser?.lastName}!</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Acasă</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Panou de control</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					
					<section className="section-area section-sp2 error-404">
						<div className="container">
						
							<Tabs activeKey={tabKey} onSelect={(e) => initTabKey(e)}>
        <Tab eventKey="one" title="Profil">
          <p>Profil</p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sed enim
            semper mi congue vestibulum.
          </p>
        </Tab>
        <Tab eventKey="two" title="Programare">
          <p>Programare</p>
        </Tab>
        <Tab eventKey="three" title="Istoric">
          <p>Istoric</p>
        </Tab>
        <Tab eventKey="four" title="Opinii">
          <p>Opinii</p>
        </Tab>
      </Tabs>
							
						</div>
					</section>
					
				</div>
				
				<Footer />
				
			</>
		);

}

export default Dasboard;